import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { ReactComponent as LinLogoSVG } from "../../resources/linLogo.svg";
import React from "react";

const Bar = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6a6b99;
    padding: 12px;
    box-sizing: border-box;
`;

const TopBar: React.FC = () => {
    return (
        <Bar>
            <Link to="https://www.lin.health/" target="_blank">
                <LinLogoSVG />
            </Link>
        </Bar>
    );
};

export default TopBar;
