import React from "react";
import SignupPage from "./pages/SignupPage";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";

const App: React.FC = () => {
    const theme = createTheme({
        typography: {
            fontFamily: ["Gilroy", "Popping", "sans-serif"].join(","),
            fontSize: 14,
            fontWeightRegular: 400,
            fontWeightBold: 700,
            fontWeightMedium: 500,
            fontWeightLight: 300,
            allVariants: { color: "#454555" },
            h6: { textAlign: "center" },
            button: {
                textTransform: "none",
            },
        },
        palette: {
            primary: { dark: "#4a4a6b", main: "#6A6B99", light: "#8788ad" },
            secondary: { dark: "#898989", main: "#C4C4C4", light: "#cfcfcf" },
            error: { dark: "#a22e3a", main: "#E84353", light: "#ec6875" },
            success: { dark: "#098551", main: "#0EBE74", light: "#3ecb8f" },
            grey: {
                50: "#ffffff",
                100: "#F7F7FD",
                200: "#EEEEF6",
                300: "#E8E9F2",
                400: "#DCDEEA",
                500: "#C7CAD7",
                600: "#AEB1C8",
                700: "#9195AA",
                800: "#686A80",
                900: "#454555",
                A100: "#30303F",
                A200: "#000000",
            },
            text: {
                primary: "#454555",
                secondary: "#C7CAD7",
                disabled: "#454555",
            },
        },
        components: {
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&.Mui-disabled": {
                            opacity: 0.5,
                        },
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    fontSizeMedium: {
                        root: {
                            color: "#898989",
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        fontSize: "0.75rem",
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        marginRight: "8px",
                        color: "#6A6B99",
                    },
                },
            },
        },
    });

    const router = createBrowserRouter(createRoutesFromElements(<Route path="/" element={<SignupPage />} />));

    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    );
};

export default App;
