
export const sortByProp =
    (property: string, order: "asc" | "desc" = "asc", propType?: "number" | "string" | "date") =>
    (a: any, b: any) => {
        const sortOrder = order === "asc" ? 1 : -1;

        let result = 0;
        if (propType === "number") {
            result = Number(a[property]) < Number(b[property]) ? -1 : Number(a[property]) > Number(b[property]) ? 1 : 0;
        } else {
            result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        }

        return result * sortOrder;
    };