import styled from "@emotion/styled";
import TopBar from "./components/TopBar";
import { useCallback, useState } from "react";
import LoadingScreen from "./components/LoadingScreen";
import DetailsForm from "./components/DetailsForm";
import React from "react";
import { ISignupDetails, IUserSignupDetails } from "../services/types";
import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";
import { signupUser } from "../services/helpers";

const PageContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f7f7fd;
    overflow: auto;
    row-gap: 45px;
`;

const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #f7f7fd;
    flex-direction: column;
`;

const SignupPage: React.FC = () => {
    const [creatingMemberLoading, setCreatingMemberLoading] = useState(false);

    const [searchParams] = useSearchParams();

    const getSearchParamsObject = useCallback(() => {
        let params: any = {};

        for (const entry of searchParams.entries()) {
            const [key, value] = entry;

            params = { ...params, [key]: value };
        }

        return params;
    }, [searchParams]);

    const onSubmitReferral = useCallback(
        async (userData: ISignupDetails) => {
            setCreatingMemberLoading(true);

            const referralMemberEmail =
                userData.memberEmail || createValidEmail(userData.memberFirstName, userData.memberLastName);

            let body: IUserSignupDetails = {
                name: `${userData.memberFirstName.trim()} ${userData.memberLastName.trim()}`,
                firstName: userData.memberFirstName,
                lastName: userData.memberLastName,
                email: referralMemberEmail,
                phone: userData.memberPhoneNumber.replace(/\s/g, ""),
                state: userData.memberState,
                flow: "*flow_wellness",
                btn_src: "",
                ref: "",
                utm_audience: "",
                utm_source: "",
                utm_medium: "",
                utm_content: "",
                utm_campaign: "",
                internal_ref: "",
                keyword: "",
                adgroup: "",
                internal_url: document.referrer || "",
                gaid: "",
                payerDetails: userData.payerDetails,
                insuranceCPID: userData.memberInsuranceCPID,
                mt: "",
                internalRef: "",
                internalUrl: document.referrer || "",
                gclid: "",
                os: "",
                dateOfBirth: userData.memberDateOfBirth,
                timezone: DateTime.now().zoneName,
                is_self_referred_by_provider: userData.isSelfReferredByProvider,
                self_referred_provider_name: userData.selfReferredProviderName,
                self_referred_clinic_name: userData.selfReferredClinic,
            };

            try {
                const params = getSearchParamsObject();
                body = { ...body, ...params };

                const res = await signupUser(body);

                if (res?.redirectUrl) {
                    window.location.replace(res.redirectUrl);
                }
            } catch (err) {
                setCreatingMemberLoading(false);
            }
        },
        [getSearchParamsObject]
    );

    return (
        <PageContainer>
            <TopBar />
            <BottomContainer>
                <DetailsForm onSubmitReferral={onSubmitReferral} />
            </BottomContainer>
            {creatingMemberLoading && <LoadingScreen />}
        </PageContainer>
    );
};

export default SignupPage;

const createValidEmail = (firstName: string, lastName: string) => {
    const emailValidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const email = `member+${firstName.toLowerCase().replace(/\s/g, "")}${lastName
        .toLowerCase()
        .replace(/\s/g, "")}@gen.mylin.health`;

    if (emailValidation.test(email)) {
        return email; // valid email address
    } else {
        return email.replace(/[^a-zA-Z0-9._%+-@]/g, ""); // remove invalid characters
    }
};
