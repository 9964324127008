import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IPayer, ISignupDetails } from "../../services/types";
import { getPayersList } from "../../services/helpers";
import { sortByProp } from "../../services/common";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { DateTime } from "luxon";

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    boxShadow: "0px 2px 12px rgba(106, 107, 153, 0.15)",
    borderRadius: 4,
    padding: "30px 24px 40px 24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 380,
}));

const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "District of Columbia",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "Washington, D.C.",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
];

const emailValidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const DetailsForm: React.FC<{
    onSubmitReferral: Function;
}> = ({ onSubmitReferral }) => {
    const [loading, setLoading] = useState(false);
    const [selectedState, setSelectedState] = useState<string | null>(null);
    const [payers, setPayers] = useState<IPayer[]>([]);

    const {
        handleSubmit,
        control,
        formState: { errors },
        resetField,
        watch,
    } = useForm<ISignupDetails>();

    const theme = useTheme();
    const isFromProvider = watch("isSelfReferredByProvider");

    const dobLimitValues = useMemo(() => {
        const _18YearsAgo = DateTime.now().minus({ years: 18 });
        const _120YearsAgo = DateTime.now().minus({ years: 120 });
        const min = _120YearsAgo.toFormat("yyyy-MM-dd");
        const max = _18YearsAgo.toFormat("yyyy-MM-dd");
        return { min, max };
    }, []);

    useEffect(() => {
        if (!payers.length) {
            getPayersList().then((res) => {
                if (res) {
                    setPayers(res.sort(sortByProp("original_name")));
                }
            });
        }
    }, [payers.length]);

    const statePayerList = useMemo(
        () => payers?.filter((p) => p.state === selectedState) || [],
        [payers, selectedState]
    );

    const LHMGsupportedStates = useMemo(() => [...new Set(payers.map((p) => p.state))], [payers]);

    const PayerSelect = useMemo(() => {
        if (!selectedState || !LHMGsupportedStates.includes(selectedState)) {
            return null;
        }
        const payersOptions = [
            ...statePayerList,
            {
                full_name: "Other",
                name: "Other",
                state: selectedState,
                cpid: "Other",
                original_name: "Other",
            },
            {
                full_name: "No insurance coverage",
                name: "No insurance coverage",
                state: selectedState,
                cpid: "NoInsurance",
                original_name: "No insurance coverage",
            },
        ];
        return (
            <Controller
                name="memberInsuranceCPID"
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field }) => {
                    return (
                        <Autocomplete
                            multiple={false}
                            fullWidth
                            options={payersOptions}
                            value={payersOptions.find((p) => p.cpid === field.value) || null}
                            getOptionLabel={(s) => `${s.full_name}`}
                            onChange={(e, selectedPayer) => {
                                field.onChange(selectedPayer?.cpid);
                            }}
                            popupIcon={<ExpandMoreRoundedIcon />}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Insurance"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!errors.memberInsuranceCPID}
                                    helperText={
                                        errors?.memberInsuranceCPID?.type === "required"
                                            ? "This field is required"
                                            : undefined
                                    }
                                    required
                                />
                            )}
                        />
                    );
                }}
            />
        );
    }, [LHMGsupportedStates, control, errors.memberInsuranceCPID, selectedState, statePayerList]);

    const onSubmit: SubmitHandler<ISignupDetails> = async (data) => {
        setLoading(true);

        if (["Other", "NoInsurance"].includes(data.memberInsuranceCPID)) {
            data.payerDetails = data.memberInsuranceCPID;
        } else {
            data.payerDetails = payers.find((p) => p.cpid === data.memberInsuranceCPID)?.name || "Other";
        }

        await onSubmitReferral(data);
        setLoading(false);
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} sx={{ marginBottom: "40px" }}>
                    Let’s get started
                </Typography>
                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} width="100%">
                    <Grid container width={"100%"}>
                        <Grid item xs={6} pr={1}>
                            <Controller
                                name="memberFirstName"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth={true}
                                        label="First name"
                                        required
                                        variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!errors.memberFirstName}
                                        helperText={
                                            errors?.memberFirstName?.type === "required"
                                                ? "This field is required"
                                                : undefined
                                        }
                                        placeholder="John"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} pl={1}>
                            <Controller
                                name="memberLastName"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        label="Last name"
                                        required
                                        variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!errors.memberLastName}
                                        helperText={
                                            errors?.memberLastName?.type === "required"
                                                ? "This field is required"
                                                : undefined
                                        }
                                        placeholder="Doe"
                                        fullWidth={true}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Controller
                        name="memberDateOfBirth"
                        control={control}
                        rules={{
                            required: true,
                            validate: (v) =>
                                DateTime.fromISO(v).startOf("day") <=
                                    DateTime.now().minus({ years: 18 }).startOf("day") &&
                                DateTime.fromISO(v).startOf("day") >
                                    DateTime.now().minus({ years: 120 }).startOf("day"),
                        }}
                        render={({ field }) => {
                            return (
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        type="date"
                                        variant="standard"
                                        label="Date of birth"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            inputProps: {
                                                min: dobLimitValues.min,
                                                max: dobLimitValues.max,
                                            },
                                        }}
                                        {...field}
                                        required
                                        fullWidth
                                        error={!!errors.memberDateOfBirth}
                                        helperText={
                                            errors?.memberDateOfBirth?.type === "required"
                                                ? "This field is required"
                                                : errors?.memberDateOfBirth?.type === "validate"
                                                ? "Date of birth is invalid"
                                                : undefined
                                        }
                                    />
                                </div>
                            );
                        }}
                    />
                    <Controller
                        name="memberPhoneNumber"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) => matchIsValidTel(value),
                        }}
                        render={({ field, fieldState }) => (
                            <MuiTelInput
                                {...field}
                                onlyCountries={["US"]}
                                defaultCountry="US"
                                label="Mobile number"
                                variant="standard"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                forceCallingCode={true}
                                disableDropdown={true}
                                error={!!errors.memberPhoneNumber || fieldState.invalid}
                                helperText={
                                    errors?.memberPhoneNumber?.type === "required"
                                        ? "This field is required"
                                        : fieldState.invalid
                                        ? "Invalid phone number"
                                        : undefined
                                }
                                fullWidth={true}
                                placeholder="(201) 555-0123"
                            />
                        )}
                    />
                    <Controller
                        name="memberEmail"
                        control={control}
                        rules={{
                            pattern: {
                                value: emailValidation,
                                message: "Please enter a valid email",
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                error={!!errors.memberEmail}
                                label="Email"
                                placeholder="name@example.com"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    errors?.memberEmail?.type === "pattern" ? "Please enter a valid email" : undefined
                                }
                                fullWidth={true}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="memberState"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return (
                                <Autocomplete
                                    renderInput={(params) => (
                                        <TextField
                                            variant="standard"
                                            {...params}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    fontSize: "15px",
                                                },
                                            }}
                                            fullWidth={true}
                                            required={true}
                                            placeholder="Please type or select your state"
                                            label="State"
                                            error={!!errors.memberState}
                                            helperText={
                                                errors?.memberState?.type === "required"
                                                    ? "This field is required"
                                                    : undefined
                                            }
                                        />
                                    )}
                                    {...field}
                                    fullWidth
                                    options={states}
                                    value={states.find((s) => s === field.value) || null}
                                    onChange={(event, value) => {
                                        setSelectedState(value);
                                        resetField("memberInsuranceCPID", { defaultValue: undefined });
                                        field.onChange(value);
                                    }}
                                    popupIcon={<ExpandMoreRoundedIcon />}
                                />
                            );
                        }}
                    />
                    {PayerSelect}
                    <Controller
                        name="isSelfReferredByProvider"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => {
                            return (
                                <FormControlLabel
                                    control={<Checkbox size="small" checked={field.value} {...field} />}
                                    label="My provider told me about Lin"
                                    color="green"
                                />
                            );
                        }}
                    />
                    <Collapse in={isFromProvider} sx={{ width: "100%" }}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                            width="100%"
                        >
                            <Controller
                                name="selfReferredProviderName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="Provider Name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Jane Doe, MD"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="selfReferredClinic"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="Clinic Name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="My Clinic"
                                        {...field}
                                    />
                                )}
                            />
                        </Stack>
                    </Collapse>
                </Stack>
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disableElevation
                    size="large"
                    disabled={loading}
                    sx={{ color: theme.palette.grey[50], textTransform: "none", marginTop: "40px" }}
                    endIcon={
                        loading ? (
                            <CircularProgress
                                size={15}
                                sx={{
                                    color: theme.palette.secondary.dark,
                                }}
                            />
                        ) : undefined
                    }
                >
                    {loading ? "Loading..." : "Continue"}
                </Button>
            </form>
        </Container>
    );
};

export default DetailsForm;
