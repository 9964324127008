import styled from "@emotion/styled";
import LinLogo from "../../resources/LinLogoNoText.png";
import { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(255 255 255 / 90%);
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LoadingScreen: React.FC = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
        }, 100);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const Loader = useMemo(() => {
        return (
            <Box sx={{ position: "relative", display: "inline-flex", marginBottom: "10px" }}>
                <CircularProgress variant="indeterminate" size={100} value={progress} thickness={2} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img src={LinLogo} alt="lin-logo" style={{ maxHeight: 70 }} />
                </Box>
            </Box>
        );
    }, [progress]);

    return (
        <Container>
            <LoaderContainer>
                {Loader}
                <Typography variant="body2" component="div" color="text.primary" fontWeight={500}>
                    Signing up...
                </Typography>
                <Typography variant="body2" component="div" color="text.primary" fontWeight={500}>
                    This may take a moment
                </Typography>
            </LoaderContainer>
        </Container>
    );
};

export default LoadingScreen;
