import { IUserSignupDetails } from "./types";

export const getPayersList = async () => {
    const response = await fetch(`${process.env.REACT_APP_FARUK}/public/signup/payer/all`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    });

    const res = await response.json();

    return res;
};

export const signupUser = async (data: IUserSignupDetails) => {
    const response = await fetch(`https://webhooks.lin.health/website-signup`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
    });

    const res = await response.json();

    return res;
};
